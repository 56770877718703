<template>
  <div>
    <div
      class="canvas-container"
      style="width: 100%; height: 100%;"
    >
      <div style="text-align: left;font-size: 14px;padding:15px;background-color: white;">
        <i class="el-icon-cloudy-and-sunny" /> 今日微博热搜词云 <span
          style="margin-left: 5px;color: #409EFF;cursor: pointer;"
          @click="updateInfo()"
        >点击刷新</span>
      </div>
      <canvas
        id="canvas"
        style="width: 60vw;height: 35vw;"
      />
      <span class="watermark">{{ formattedDate }}微博热搜词云<br>词云来源：热搜引擎</span>
    </div>
  </div>
</template>

<script>
    import {getList} from "@/api/wordCloud";

    export default {
        data() {
            return {
                wordData: {},
                formattedDate: '',
                date: ''
            };
        },
        mounted() {
            const that = this;
            that.getFormattedDate()
            that.getList();
        },
        beforeDestroy() {
            const that = this;
            that.getList();
        },
        methods: {
            updateInfo() {
                const that = this;
                that.getList();
            },
            handleDateChange(value) {
                if (value) {
                    const dateObj = new Date(value);
                    const year = dateObj.getFullYear();
                    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
                    const day = ('0' + dateObj.getDate()).slice(-2);
                    this.formattedDate = `${year}-${month}-${day}`;
                } else {
                    this.formattedDate = '';
                }
                this.getList();
            },
            getFormattedDate() {
                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                const day = currentDate.getDate().toString().padStart(2, '0');
                this.formattedDate = `${year}-${month}-${day}`;
            },
            getList() {
                getList(this.formattedDate)
                    .then((res) => {
                        this.wordData = res.data.value;
                        var arrResult = [];
                        for (var key in this.wordData) {
                            var arr1 = [key, this.wordData[key]];
                            arrResult.push(arr1)
                        }
                        const canvas = document.getElementById('canvas');
                        const width = document.getElementById("searchCary").width;
                        console.log(width); // 输出Canvas元素的宽度值
                        canvas.width = 1100;
                        canvas.height = document.documentElement.clientHeight - 250;
                        var options = eval({
                            "list": arrResult, //或者[['各位观众',45],['词云', 21],['来啦!!!',13]],只要格式满足这样都可以
                            "gridSize": 3, // 密集程度 数字越小越密集
                            "weightFactor": 15, // 字体大小=原始大小*weightFactor
                            "maxFontSize": 60, //最大字号
                            "minFontSize": 14, //最小字号
                            "fontWeight": 'normal', //字体粗细
                            "fontFamily": 'Times, serif', // 字体
                            "color": 'random-light', // 字体颜色 'random-dark' 或者 'random-light'
                            "backgroundColor": '#FFF', // 背景颜色
                            "rotateRatio": 1, // 字体倾斜(旋转)概率，1代表总是倾斜(旋转)
                            "hover": window.drawBox,
                            "click": function (item) {
                                console.log(item)
                                window.open("../?keyword=" + item[0])
                            },
                        });
                        WordCloud(canvas, options);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        },
    };
</script>
<style scoped>
    #canvas {
        border-radius: 10px;
    }

    .canvas-container {
        position: relative;
    }

    .watermark {
        position: absolute;
        top: 90%;
        right: 0;
        font-size: 1vw;
        color: rgba(0, 0, 0, 0.3);
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    .selectDate {
        position: fixed;
        right: 20px;
        bottom: 50px;
        width: 60px;
        height: 60px;
        background-color: #409EFF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .selectDate i {
        color: #fff;
        font-size: 24px;
    }
</style>