<template>
  <div style="width: 80vw;margin: 0 auto;">
    <!--		<div style="text-align: center;">-->
    <!--			<h3>- 会员权益兑换码 -</h3>-->
    <!--			<h6>每日不定时更新</h6>-->
    <!--		</div>-->
    <div style="display: flex;justify-content: center;">
      <div
        v-for="(item,index) in datas"
        id="secretKeyCardEntitlement"
        :key="index"
        class="card"
      >
        <div class="card-header">
          <div class="card-title">
            {{ item.secretKey }}<br>({{ item.day }}天)
          </div>
        </div>
        <div class="card-content">
          <div v-if="item.userId==='******'">
            <button class="renew-button-out">
              已被使用
            </button>
          </div>
          <div v-if="item.userId!='******'">
            <button
              class="renew-button"
              @click="copy(item.secretKey)"
            >
              点击复制
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {secretKeyList} from "../api/secretKey.js";

  export default {
    name: "SecretKey",
    data() {
      return {
        datas: []
      }
    },
    created() {

    },
    mounted() {
      var that = this;
			that.getList()
		},
		methods: {
			getList() {
				var that = this;
				secretKeyList(1).then(res => {
					console.log(res.data.data)
					that.datas = res.data.data
				}).catch((err) => {
					console.log(err)
				})
			},
			copy(secretKey) {
				this.$copyText(secretKey).then(e => {
					console.log(e)
					this.$notify({
						title: '复制成功',
						message: '兑换路径：个人中心-兑换码',
						offset: 100,
						duration: 3000
					});
				})
			}
		}
	}
</script>

<style>
	#secretKeyCardEntitlement {
		color: #fff;
		width: 20%;
		border-radius: 10px;
		background: url('../assets/secbg.png')no-repeat center center;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		margin: 5px;
	}

	#secretKeyCardEntitlement .card-header {
		margin-bottom: 10px;
	}

	#secretKeyCardEntitlement .card-title {
		font-size: 18px;
		margin: 0;
		font-weight: bold;
		padding: 5px;
	}

	#secretKeyCardEntitlement .expiry-date {
		font-size: 14px;
	}

	#secretKeyCardEntitlement .card-content {
		position: relative;
		padding: 5px;
	}

	#secretKeyCardEntitlement .renew-button {
		font-size: 14px;
		color: #fff;
		background-color: #2196f3;
		border: none;
		border-radius: 5px;
		cursor: pointer;
	}

	#secretKeyCardEntitlement .renew-button-out {
		font-size: 14px;
		color: #fff;
		background-color: #aaaa7f;
		border: none;
		border-radius: 5px;
		cursor: pointer;
	}
</style>