<template>
  <div
    id="payCardEntitlement"
    class="card"
  >
    <div class="card-header">
      <div
        v-if="isShow!==1"
        class="card-title"
      >
        尚未登录
      </div>
      <div v-if="isShow===1">
        <h2
          v-if="paymentType===1"
          class="card-title"
        >
          限时试用
        </h2>
        <h2
          v-if="paymentType===2"
          class="card-title"
        >
          月度畅享权益
        </h2>
        <h2
          v-if="paymentType===3"
          class="card-title"
        >
          季度畅享权益
        </h2>
        <h2
          v-if="paymentType===4"
          class="card-title"
        >
          年度畅享权益
        </h2>
        <span class="expiry-date">有效期至{{ expireTime }}</span>
        <span
          v-if="isExpire!==1"
          class="expiry-date"
        ><br>(已过期)</span>
      </div>
    </div>
    <div class="card-content">
      <div v-if="isShow===1">
        <button
          v-if="isExpire!==1 && paymentType!==1"
          class="renew-button"
          @click="gotoPay()"
        >
          重新获取
        </button>
        <button
          v-if="isExpire===1 && paymentType===1"
          class="renew-button"
          @click="gotoPay()"
        >
          升级
        </button>
        <button
          v-if="isExpire!==1 && paymentType===1"
          class="renew-button"
          @click="gotoPay()"
        >
          重新获取
        </button>
      </div>
      <div v-if="isShow!==1">
        <button
          class="renew-button"
          @click="gotoLogin()"
        >
          登录获取权益信息
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {info} from "../api/user.js";

  export default {
    name: "EntitlementCard",
    props: {
      entitlement: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        expireTime: "",
				paymentType: 1,
				isExpire: 0,
				isShow: 1
			}
		},
		created() {

		},
		mounted() {
			var that = this;
			that.getInfo()
		},
		methods: {
			gotoPay() {
				this.$router.push({
					name: 'user',
					query: {
						isShow: true
					}
				})
			},
			gotoLogin() {
				this.$router.push({
					name: 'login'
				})
			},
			getInfo() {
				var that = this;
				info().then(res => {
					if (res.data.data === undefined) {
						that.isShow = 0
						return;
					}
					that.isShow = 1
					that.expireTime = res.data.data.expireTime
					that.paymentType = res.data.data.paymentType
					that.isExpire = res.data.data.isExpire
				}).catch((err) => {
					console.log(err)
				})
			}
		}
	}
</script>

<style>
	#payCardEntitlement {
		width: 100%;
		color: #fff;
		border-radius: 10px;
		background-image: url('../assets/cardbg.jpg');
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}

	#payCardEntitlement .card-header {
		margin-bottom: 10px;
	}

	#payCardEntitlement .card-title {
		font-size: 20px;
		margin: 0;
		padding: 5px;
	}

	#payCardEntitlement .expiry-date {
		font-size: 14px;
	}

	#payCardEntitlement .card-content {
		position: relative;
		padding: 5px;
	}

	#payCardEntitlement .renew-button {
		font-size: 14px;
		color: #fff;
		background-color: #2196f3;
		border: none;
		border-radius: 5px;
		cursor: pointer;
	}
</style>